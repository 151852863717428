import "./Home.scss";
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

export default class Home extends Component {

    age = this.getAge("1996-12-03");

    getAge(dateString) {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    render() {

        return (
            <div>
                <div className="title-section">
                    <div className="photo-container">
                        <div className="photo"/>
                        <div className="text">
                            <h1>Moin!</h1>
                            <p><Trans i18nKey="title.photo.name">I'm Henning</Trans></p>
                            <p style={{ fontSize: "32px" }}><Trans i18nKey="title.photo.job">
                                Software Engineer. Part-Time CEO. Hobby-Cyclist.
                            </Trans></p>
                            <p className="get-in-touch-link">
                                <Trans i18nKey="title.photo.inTouch">Just want to get in touch?</Trans>
                                <Link to="/contact"><Trans i18nKey="title.photo.clickHere">Click here</Trans></Link>
                            </p>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="wave">
                        <defs>
                            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" stopColor="#FFAA33"/>
                                <stop offset="100%" stopColor="#DD1188"/>
                            </linearGradient>
                        </defs>
                        <path fill="url(#gradient)" fillOpacity="1"
                              d="M0,96L18.5,96C36.9,96,74,96,111,101.3C147.7,107,185,117,222,138.7C258.5,160,295,192,332,202.7C369.2,213,406,203,443,186.7C480,171,517,149,554,133.3C590.8,117,628,107,665,122.7C701.5,139,738,181,775,202.7C812.3,224,849,224,886,218.7C923.1,213,960,203,997,176C1033.8,149,1071,107,1108,90.7C1144.6,75,1182,85,1218,122.7C1255.4,160,1292,224,1329,218.7C1366.2,213,1403,139,1422,101.3L1440,64L1440,320L1421.5,320C1403.1,320,1366,320,1329,320C1292.3,320,1255,320,1218,320C1181.5,320,1145,320,1108,320C1070.8,320,1034,320,997,320C960,320,923,320,886,320C849.2,320,812,320,775,320C738.5,320,702,320,665,320C627.7,320,591,320,554,320C516.9,320,480,320,443,320C406.2,320,369,320,332,320C295.4,320,258,320,222,320C184.6,320,148,320,111,320C73.8,320,37,320,18,320L0,320Z"></path>
                    </svg>
                </div>
                <div className="cv-background">
                    <div>
                        <h1><Trans i18nKey="title.about.title">Über mich</Trans> 🤗 👀 🙆‍♂️</h1>
                        <div className="cv-item-container">
                            <div className="headline">BASICS</div>
                            <div className="cv-item">
                                <div className="cv-item-color" style={{ background: "#FFAA33" }}/>
                                <div className="cv-item-text">
                                    {this.age}&nbsp;
                                    <Trans i18nKey="title.about.basics1">
                                        years old from a village in north-west germany
                                    </Trans>
                                </div>
                            </div>
                            <div className="cv-item">
                                <div className="cv-item-color" style={{ background: "#e74569" }}/>
                                <div className="cv-item-text">
                                    <Trans i18nKey="title.about.basics2">
                                        M.Sc. Business Informatics (Waiting for my transcript)
                                    </Trans>
                                </div>
                            </div>
                            <div className="cv-item">
                                <div className="cv-item-color" style={{ background: "#DD1188" }}/>
                                <div className="cv-item-text">
                                    <Trans i18nKey="title.about.basics3">
                                        Software Engineer since 2019, part-time CEO since 2021
                                    </Trans>
                                </div>
                            </div>
                        </div>
                        <div className="cv-item-container" style={{ alignSelf: "stretch" }}>
                            <div className="headline">HOBBY</div>
                            <div className="emoji-container">
                                🚴 🧑‍💻 🐕 ☎ 🤯
                            </div>
                        </div>
                        <div className="cv-item-container">
                            <div className="headline">SKILLS</div>
                            <div className="cv-item">
                                <div className="cv-item-color" style={{ background: "#FFAA33" }}/>
                                <div className="cv-item-text">
                                    <Trans i18nKey="title.about.skills1">
                                        Introverted, but love working with interesting people
                                    </Trans>
                                </div>
                            </div>
                            <div className="cv-item">
                                <div className="cv-item-color" style={{ background: "#e74569" }}/>
                                <div className="cv-item-text">
                                    <Trans i18nKey="title.about.skills2">
                                        Taking on new challenges, learning new things
                                    </Trans>
                                </div>
                            </div>
                            <div className="cv-item">
                                <div className="cv-item-color" style={{ background: "#DD1188" }}/>
                                <div className="cv-item-text">
                                    <Trans i18nKey="title.about.skills3">
                                        Respectful, but honest with the right amount of humor
                                    </Trans>
                                </div>
                            </div>
                        </div>
                        <div className="cv-item-container tech-item-container">
                            <div className="headline">TECH</div>
                            <div className="tech-item">Git</div>
                            <div className="tech-item">Docker</div>
                            <div className="tech-item">Kubernetes</div>
                            <div className="tech-item">Rancher</div>
                            <div className="tech-item">CI/CD</div>
                            <div className="tech-item">Java</div>
                            <div className="tech-item">Spring Boot</div>
                            <div className="tech-item">Micronaut</div>
                            <div className="tech-item">Kotlin</div>
                            <div className="tech-item">Scala</div>
                            <div className="tech-item">Play</div>
                            <div className="tech-item">Go</div>
                            <div className="tech-item">Angular</div>
                            <div className="tech-item">Vue.js</div>
                            <div className="tech-item">React</div>
                            <div className="tech-item">Node.js</div>
                            <div className="tech-item">Typescript</div>
                            <div className="tech-item">Javascript</div>
                            <div className="tech-item">PHP (sadly)</div>
                            <div className="tech-item">Cobol</div>
                            <div className="tech-item">Bash</div>
                            <div className="tech-item">Linux</div>
                            <div className="tech-item">Postgres</div>
                            <div className="tech-item">Oracle</div>
                            <div className="tech-item">Python</div>
                            <div className="tech-item">Tensorflow</div>
                            <div className="tech-item">Scikit-Learn</div>
                            <div className="tech-item">...</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
