import "./Portfolio.scss";
import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";

export default class Portfolio extends Component {

    readMore(id) {
        document.getElementById(id).className = "portfolio-item";
    }

    readLess(id) {
        document.getElementById(id).className = "portfolio-item portfolio-item-show-less";
    }

    render() {
        return (
            <div className="portfolio">
                <h1><Trans i18nKey="portfolio.title">Check out what I did so far:</Trans></h1>
                <div className="portfolio-item portfolio-item-show-less" id="paloma">
                    <img src={require("../../assets/secret-project_dove.png")} alt="secret-project logo"
                         style={{background: '#673ab7'}}/>
                    <div className="field">
                        <Trans i18nKey="portfolio.paloma.field">Insurance</Trans>
                    </div>
                    <div className="content">
                        <h1>Paloma</h1>
                        <div>
                            <Trans i18nKey="portfolio.paloma.description">
                                Paloma is an all in one platform for managing the complete lifecycle of life and funeral
                                insurances including member and contract management, bookkeeping, payment transactions,
                                correspondence, data migration, and web workflows.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.role">My Role</Trans></h3>
                        <div>
                            <Trans i18nKey="portfolio.paloma.role">
                                I am the co-founder of Evonetic Software GmbH, the company behind the paloma software.
                                Together with my partner Jon-Patric Ewelt, we built paloma from the ground up
                                and continue to improve Paloma according to the need of our customers
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.learnMore">Learn more</Trans></h3>
                        <div className="links">
                            <a href="https://evonetic.de" target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.paloma.website">
                                    > Official project website
                                </Trans>
                            </a>
                        </div>
                    </div>
                    <div className="show-more" onClick={() => this.readMore("paloma")}>
                        <a><Trans i18nKey="portfolio.readMore">> Read more</Trans> &lt;</a>
                    </div>
                </div>
                <div className="portfolio-item portfolio-item-show-less" id="rocit">
                    <img src={require("../../assets/rocit_logo.png")} alt="rocit logo"
                         style={{background: '#009587'}}/>
                    <div className="field">
                        <Trans i18nKey="portfolio.rocit.field">Public Transport</Trans>
                    </div>
                    <div className="content">
                        <h1>ROCIT</h1>
                        <div>
                            <Trans i18nKey="portfolio.rocit.description">
                                ROCIT is a forecast and delay analysis tool for public transport companies. It
                                integrates
                                into the data model of the common german standard for public bus transport
                                (VDV-452/453/454)
                                to provide the operator with the most critical information in realtime and in
                                retrospect.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.role">My Role</Trans></h3>
                        <div>
                            <Trans i18nKey="portfolio.rocit.role">
                                I lead the frontend team, which task was to build the ROCIT single page application. I
                                also took part in building the ETL processes for the data warehouse. Moreover, I partly
                                took over the lead role for the AI team, building a realtime forecasting API using
                                tensorflow.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.learnMore">Learn more</Trans></h3>
                        <div className="links">
                            <a href="https://rocit.space" target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.rocit.website">
                                    > Official project website
                                </Trans>
                            </a>
                            <a href="https://www.researchgate.net/publication/346355444_Improving_Delay_Forecasts_in_Public_Transport_using_Machine_Learning_Techniques"
                               target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.rocit.paper">
                                    > Published Paper
                                </Trans>
                            </a>
                            <a href="http://cyprusconferences.org/enviroinfo2020/" target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.rocit.enviroinfo">
                                    > Enviroinfo 2020
                                </Trans>
                            </a>
                        </div>
                    </div>
                    <div className="show-more" onClick={() => this.readMore("rocit")}>
                        <a><Trans i18nKey="portfolio.readMore">> Read more</Trans> &lt;</a>
                    </div>
                </div>
                <div className="portfolio-item portfolio-item-show-less" id="imis">
                    <img src={require("../../assets/imis_logo.png")} alt="imis logo"
                         style={{background: '#40a9ff'}}/>
                    <div className="field"><Trans i18nKey="portfolio.imis.field">Health / COVID-19</Trans></div>
                    <div className="content">
                        <h1>IMIS</h1>
                        <div>
                            <Trans i18nKey="portfolio.imis.description">
                                IMIS provides a digital, standardized way for health departments to record and report
                                COVID-19 cases. It was originally developed as part of the "Wir-vs-Virus"-Hackathon
                                organized by the german government. After its initial development, it was further
                                developed
                                until resources were transferred to the existing application "SORMAS".
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.role">My Role</Trans></h3>
                        <div>
                            <Trans i18nKey="portfolio.imis.role">
                                I took an important part in building the frontend application of IMIS. To a lesser
                                extend, I
                                worked on the backend, developing the IMIS REST API. I also was responsible for
                                building the project website.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.learnMore">Learn more</Trans></h3>
                        <div className="links">
                            <a href="https://imis-prototyp.de/" target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.imis.website">
                                    > Official project website
                                </Trans>
                            </a>
                            <a href="https://twitter.com/IMIS_team" target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.imis.twitter">
                                    > IMIS Twitter
                                </Trans>
                            </a>
                            <a href="https://github.com/ImisDevelopers/1_011_a_infektionsfall_uebermittellung"
                               target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.imis.repo">
                                    > Github Repository
                                </Trans>
                            </a>
                        </div>
                    </div>
                    <div className="show-more" onClick={() => this.readMore("imis")}>
                        <a><Trans i18nKey="portfolio.readMore">> Read more</Trans> &lt;</a>
                    </div>
                </div>
                <div className="portfolio-item portfolio-item-show-less" id="simplemattable">
                    <img src={"https://smc-demo.simplex24.de/assets/npm_logo.svg"} alt="npm logo"
                         style={{background: '#ccc'}}/>
                    <div className="field"><Trans i18nKey="portfolio.simplemattable.field">UI Library</Trans></div>
                    <div className="content">
                        <h1>Simplemattable</h1>
                        <div>
                            <Trans i18nKey="portfolio.simplemattable.description">
                                Simplemattable is an open source Angular-library for easy and flexible creation of
                                material
                                design tables. It has been in continuous commercial use since 2018.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.role">My Role</Trans></h3>
                        <div>
                            <Trans i18nKey="portfolio.simplemattable.role">
                                I created the project in 2018 and have been maintaining and developing the library since
                                then. With ideas and suggestions from the community, I continue to improve
                                simplemattable one feature at a time.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.learnMore">Learn more</Trans></h3>
                        <div className="links">
                            <a href="https://smc-demo.simplex24.de/" target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.simplemattable.website">
                                    > Official project website
                                </Trans>
                            </a>
                            <a href="https://www.npmjs.com/package/simplemattable" target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.simplemattable.npm">
                                    > NPM Package
                                </Trans>
                            </a>
                            <a href="https://github.com/wobkenh/simplematcomponents" target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.simplemattable.repo">
                                    > Github Repository
                                </Trans>
                            </a>
                        </div>
                    </div>
                    <div className="show-more" onClick={() => this.readMore("simplemattable")}>
                        <a><Trans i18nKey="portfolio.readMore">> Read more</Trans> &lt;</a>
                    </div>
                </div>
                <div className="portfolio-item portfolio-item-show-less" id="skip">
                    <img src={require("../../assets/skip_icon.png")} alt="portfolio-item-image"
                         style={{background: '#2196F3'}}/>
                    <div className="field"><Trans i18nKey="portfolio.skip.field">Software Testing</Trans></div>
                    <div className="content">
                        <h1>Skip</h1>
                        <div>
                            <Trans i18nKey="portfolio.skip.description1">
                                Skip is a test automation tool that can be used for system and acceptance testing. It
                                provides a framework for developers to create and publish test templates.
                                By providing a configuration for these templates, test cases can be created, which can
                                be
                                done by people without an IT background.
                            </Trans>
                            <br/>
                            <br/>
                            <Trans i18nKey="portfolio.skip.description2">
                                Skip seamlessly integrates into modern CI/CD infrastructure such as build sytems,
                                version
                                control systems and kubernetes clusters and provides a plugin-system for rapid creation
                                of
                                test templates.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.role">My Role</Trans></h3>
                        <div>
                            <Trans i18nKey="portfolio.skip.role">
                                I developed Skip as a part of my master thesis about the automation of system and
                                acceptance
                                tests in IT landscapes. For this, I organized and moderated several workshops with
                                different
                                groups of stakeholders in a german bank. The workshops resulted in user stories, which I
                                took into account during the design and implementation of Skip.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.learnMore">Learn more</Trans></h3>
                        <div style={{paddingBottom: "16px"}}>
                            <Trans i18nKey="portfolio.skip.learnMore">
                                Skip is currently closed source and my master thesis can
                                not be published due to a blocking
                                notice. If you are interested in Skip or my master thesis, feel free to contact me. I am
                                sure we can work something out.
                            </Trans>
                        </div>
                        <div className="links">
                            <Link to="/contact">
                                <Trans i18nKey="portfolio.skip.contact">
                                    > Contact me
                                </Trans>
                            </Link>
                        </div>
                    </div>
                    <div className="show-more" onClick={() => this.readMore("skip")}>
                        <a><Trans i18nKey="portfolio.readMore">> Read more</Trans> &lt;</a>
                    </div>
                </div>
                <div className="portfolio-item portfolio-item-show-less" id="vpex">
                    <img src={require("../../assets/vpex_icon.png")} alt="VPEX Logo"
                         style={{background: '#1F1E2F'}}/>
                    <div className="field"><Trans i18nKey="portfolio.vpex.field">Productivity</Trans></div>
                    <div className="content">
                        <h1>Vpex</h1>
                        <div>
                            <Trans i18nKey="portfolio.vpex.description1">
                                VPEX is an easy to use and lightweight editor for large text/xml files. It allows you
                                to&nbsp;
                                <strong>V</strong>iew, <strong>P</strong>arse (syntax and schema validation)
                                and <strong>E</strong>dit large <strong>X</strong>ML files.
                            </Trans>
                            <br/>
                            <br/>
                            <Trans i18nKey="portfolio.vpex.description2">
                                VPEX was designed to be quick and reliable while working with files of any size.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.role">My Role</Trans></h3>
                        <div>
                            <Trans i18nKey="portfolio.vpex.role">
                                I created the project in 2019 since I had to deal with larger XML files and the tools at
                                my
                                disposal did not suffice my needs. With ideas from my colleagues, who are using
                                VPEX to this day, I developed VPEX to a point where we could use it comfortably in our
                                everyday work.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.learnMore">Learn more</Trans></h3>
                        <div className="links">
                            <a href="https://github.com/wobkenh/vpex" target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.vpex.repo">
                                    > Github Repository
                                </Trans>
                            </a>
                            <Link to="/contact">
                                <Trans i18nKey="portfolio.vpex.contact">
                                    > Interested in VPEX? Contact me
                                </Trans>
                            </Link>
                        </div>
                    </div>
                    <div className="show-more" onClick={() => this.readMore("vpex")}>
                        <a><Trans i18nKey="portfolio.readMore">> Read more</Trans> &lt;</a>
                    </div>
                </div>
                <div className="portfolio-item portfolio-item-show-less" id="simplex">
                    <img src={require("../../assets/simplex_logo_neu.png")} alt="portfolio-item-image"
                         style={{background: '#455A64'}}/>
                    <div className="field">
                        <Trans i18nKey="portfolio.simplex.field">
                            Financial
                        </Trans>
                    </div>
                    <div className="content">
                        <h1>Simplex</h1>
                        <div>
                            <Trans i18nKey="portfolio.simplex.description1">
                                Simplex was an online banking financial analysis tool. By providing Simplex access to
                                your
                                online banking (using the HBCI/FinTS protocol), it could give you an intuitive overview
                                of
                                your financials by categorizing your transactions, setting limits and identifying
                                unexpected
                                behaviour.
                            </Trans>
                            <br/>
                            <br/>
                            <Trans i18nKey="portfolio.simplex.description2">
                                Simplex was discontinued due to changes introduced by the EU-directive Payment Service
                                Directive II (PSD2). Since PSD2, account information services are supposed to use APIs
                                provided by the banks instead of using the existing HBCI/FinTS online banking
                                infrastructure. Due to the high expenditure associated with the redesign, we instead
                                focused
                                on other, more promising projects.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.role">My Role</Trans></h3>
                        <div>
                            <Trans i18nKey="portfolio.simplex.role">
                                I developed Simplex together with a colleague in 2017 as a way to get started with
                                Spring
                                Boot, Scala and Angular. While I was more focused on the frontend application, my
                                colleague
                                took more part in the development of the backend REST API.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.learnMore">Learn more</Trans></h3>
                        <div style={{paddingBottom: "16px"}}>
                            <Trans i18nKey="portfolio.simplex.learnMore">
                                Simplex as well as the Typescript HBCI/FinTS client "SimpleHBCI" are closed
                                source and not available to the public. If, however, you are interested in seeing what
                                and
                                how we developed or see a potential use case for any of the components, feel free to
                                contact
                                me.
                            </Trans>
                        </div>
                        <div className="links">
                            <Link to="/contact">
                                <Trans i18nKey="portfolio.simplex.contact">
                                    > Contact me
                                </Trans>
                            </Link>
                        </div>
                    </div>
                    <div className="show-more" onClick={() => this.readMore("simplex")}>
                        <a><Trans i18nKey="portfolio.readMore">> Read more</Trans> &lt;</a>
                    </div>
                </div>
                <div className="portfolio-item portfolio-item-show-less" id="browserstartpage">
                    <img src={require("../../assets/bsp_logo_white.png")} alt="portfolio-item-image"
                         style={{background: '#303f9f'}}/>
                    <div className="field">
                        <Trans i18nKey="portfolio.browserstartpage.field">Productivity</Trans>
                    </div>
                    <div className="content">
                        <h1>Browserstartpage</h1>
                        <div>
                            <Trans i18nKey="portfolio.browserstartpage.description">
                                Browserstartpage is a configurable, easy to use startpage for your browser. It allows
                                you
                                to customize your entry page to the web by embedding widgets like links, weather or
                                complete
                                foreign pages onto a part of your personal browser startpage.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.role">My Role</Trans></h3>
                        <div>
                            <Trans i18nKey="portfolio.browserstartpage.role">
                                I created the project in 2018 as I saw one of my lecturers having a personal,
                                self-created
                                html browser startpage. I started off creating a simple html page myself, but then I
                                noticed
                                I wanted something that is easier to configure and to use. I have used Browserstartpage
                                continuously since then and was able to reuse the code of the configurator in other
                                projects
                                like ROCIT, greatly increasing my development speed.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.learnMore">Learn more</Trans></h3>
                        <div className="links">
                            <a href="https://browserstartpage.simplex24.de/" target="_blank" rel="noreferrer">
                                <Trans i18nKey="portfolio.browserstartpage.website">
                                    > Official Website (incl. Sign Up Form)
                                </Trans>
                            </a>
                        </div>
                    </div>
                    <div className="show-more" onClick={() => this.readMore("browserstartpage")}>
                        <a><Trans i18nKey="portfolio.readMore">> Read more</Trans> &lt;</a>
                    </div>
                </div>
                <div className="portfolio-item portfolio-item-show-less" id="deinpils">
                    <img src={require("../../assets/dein_pils_logo.png")} alt="portfolio-item-image"
                         style={{background: '#6c7a89'}}/>
                    <div className="field">
                        <Trans i18nKey="portfolio.deinpils.field">Entertainment</Trans>
                    </div>
                    <div className="content">
                        <h1>Dein Pils</h1>
                        <div>
                            <Trans i18nKey="portfolio.deinpils.description">
                                Dein Pils ("Your Pils") is an interactive beer map of germany. Users can provide their
                                zip
                                code and vote for their favorite beer. They can then see on a map which beer is most
                                popular
                                in a zip code, county or state.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.role">My Role</Trans></h3>
                        <div>
                            <Trans i18nKey="portfolio.deinpils.role">
                                I developed Dein Pils together with a colleague in one weekend in 2020 as a joke.
                            </Trans>
                        </div>
                        <h3><Trans i18nKey="portfolio.learnMore">Learn more</Trans></h3>
                        <div style={{paddingBottom: "16px"}}>
                            <Trans i18nKey="portfolio.deinpils.learnMore">
                                The Dein Pils website is currently offline.
                            </Trans>
                        </div>
                        <div className="links">
                            <Link to="/contact">
                                <Trans i18nKey="portfolio.deinpils.contact">
                                    > Find the concept interesting? Contact me
                                </Trans>
                            </Link>
                        </div>
                    </div>
                    <div className="show-more" onClick={() => this.readMore("deinpils")}>
                        <a><Trans i18nKey="portfolio.readMore">> Read more</Trans> &lt;</a>
                    </div>
                </div>
            </div>
        );
    }
}
