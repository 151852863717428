import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    // here we will place our translations...
                    title: {
                        photo: {
                            name: "I'm Henning.",
                            job: "Software Engineer. Part-Time CEO. Hobby-Cyclist.",
                            inTouch: "Just want to get in touch?",
                            clickHere: "Click here",
                        },
                        about: {
                            title: "About me",
                            basics1: "years old from a village in north-west germany",
                            basics2: "M.Sc. Business Informatics",
                            basics3: "Software Engineer since 2019, part-time CEO since 2021",
                            skills1: "Introverted, but love working with interesting people",
                            skills2: "Taking on new challenges, learning new things",
                            skills3: "Respectful, but honest with the right amount of humor",
                        }
                    },
                    footer: {
                        imprint: "Imprint",
                        privacy: "Privacy Policy",
                        handbuilt: "Handbuilt with ♥ and ✨",
                    },
                    navbar: {
                        contact: "Contact",
                    },
                    portfolio: {
                        title: "Check out what I did so far:",
                        comingSoon: "COMING SOON",
                        role: "My Role",
                        learnMore: "Learn more",
                        readMore: "> Read more",
                        rocit: {
                            field: "Public Transport",
                            description: "ROCIT is a forecast and delay analysis tool for public transport companies. It integrates into the data model of the common german standard for public bus transport (VDV-452/453/454) to provide the operator with the most critical information in realtime and in retrospect.",
                            role: "I lead the frontend team, which task was to build the ROCIT single page application. I also took part in building the ETL processes for the data warehouse. Moreover, I partly took over the lead role for the AI team, building a realtime forecasting API using tensorflow.",
                            website: "> Official project website",
                            paper: "> Published Paper",
                            enviroinfo: "> Enviroinfo 2020",
                        },
                        paloma: {
                            field: "Insurance",
                            description: "Paloma is an all in one platform for managing the complete lifecycle of life and funeral insurances including member and contract management, bookkeeping, payment transactions, correspondence, data migration, and web workflows.",
                            role: "I am the co-founder of Evonetic Software GmbH, the company behind the paloma software. Together with my partner Jon-Patric Ewelt, we built paloma from the ground up and continue to improve Paloma according to the need of our customers",
                            website: "> Official project website",
                        },
                        imis: {
                            field: "Health / COVID-19",
                            description: "IMIS provides a digital, standardized way for health departments to record and report COVID-19 cases. It was originally developed as part of the \"Wir-vs-Virus\"-Hackathon organized by the german government. After its initial development, it was further developed until resources were transferred to the existing application \"SORMAS\".",
                            role: "I took an important part in building the frontend application of IMIS. To a lesser extend, I worked on the backend, developing the IMIS REST API. I also was responsible for building the project website.",
                            website: "> Official project website",
                            twitter: "> IMIS Twitter",
                            repo: "> Github Repository",
                        },
                        simplemattable: {
                            field: "UI Library",
                            description: "Simplemattable is an open source Angular-library for easy and flexible creation of material design tables. It has been in continuous commercial use since 2018.",
                            role: "I created the project in 2018 and have been maintaining and developing the library since then. With ideas and suggestions from the community, I continue to improve simplemattable one feature at a time.",
                            website: "> Official project website",
                            npm: "> NPM Package",
                            repo: "> Github Repository",
                        },
                        skip: {
                            field: "Software Testing",
                            description1: "Skip is a test automation tool that can be used for system and acceptance testing. It provides a framework for developers to create and publish test templates. By providing a configuration for these templates, test cases can be created, which can be done by people without an IT background.",
                            description2: "Skip seamlessly integrates into modern CI/CD infrastructure such as build sytems, version control systems and kubernetes clusters and provides a plugin-system for rapid creation of test templates.",
                            role: "I developed Skip as a part of my master thesis about the automation of system and acceptance tests in IT landscapes. For this, I organized and moderated several workshops with different groups of stakeholders in a german bank. The workshops resulted in user stories, which I took into account during the design and implementation of Skip.",
                            learnMore: "Skip is currently closed source and my master thesis can not be published due to a blocking notice. If you are interested in Skip or my master thesis, feel free to contact me. I am sure we can work something out.",
                            contact: "> Contact me",
                        },
                        vpex: {
                            field: "Productivity",
                            description1: "VPEX is an easy to use and lightweight editor for large text/xml files. It allows you to <strong>V</strong>iew, <strong>P</strong>arse (syntax and schema validation) and <strong>E</strong>dit large <strong>X</strong>ML files.",
                            description2: "VPEX was designed to be quick and reliable while working with files of any size.",
                            role: "I created the project in 2019 since I had to deal with larger XML files and the tools at my disposal did not suffice my needs. With ideas from my colleagues, who are using VPEX to this day, I developed VPEX to a point where we could use it comfortably in our everyday work.",
                            repo: "> Github Repository",
                            contact: "> Interested in VPEX? Contact me",
                        },
                        simplex: {
                            field: "Financial",
                            description1: "Simplex was an online banking financial analysis tool. By providing Simplex access to your online banking (using the HBCI/FinTS protocol), it could give you an intuitive overview of your financials by categorizing your transactions, setting limits and identifying unexpected behaviour.",
                            description2: "Simplex was discontinued due to changes introduced by the EU-directive Payment Service Directive II (PSD2). Since PSD2, account information services are supposed to use APIs provided by the banks instead of using the existing HBCI/FinTS online banking infrastructure. Due to the high expenditure associated with the redesign, we instead focused on other, more promising projects.",
                            role: "I developed Simplex together with a colleague in 2017 as a way to get started with Spring Boot, Scala and Angular. While I was more focused on the frontend application, my colleague took more part in the development of the backend REST API.",
                            learnMore: "Simplex as well as the Typescript HBCI/FinTS client \"SimpleHBCI\" are closed source and not available to the public. If, however, you are interested in seeing what and how we developed or see a potential use case for any of the components, feel free to contact me.",
                            contact: "> Contact me",
                        },
                        browserstartpage: {
                            field: "Productivity",
                            description: "Browserstartpage is a configurable, easy to use startpage for your browser. It allows you to customize your entry page to the web by embedding widgets like links, weather or complete foreign pages onto a part of your personal browser startpage.",
                            role: "I created the project in 2018 as I saw one of my lecturers having a personal, self-created html browser startpage. I started off creating a simple html page myself, but then I noticed I wanted something that is easier to configure and to use. I have used Browserstartpage continuously since then and was able to reuse the code of the configurator in other projects like ROCIT, greatly increasing my development speed.",
                            website: "> Official Website (incl. Sign Up Form)",
                        },
                        deinpils: {
                            field: "Entertainment",
                            description: "Dein Pils (\"Your Pils\") is an interactive beer map of germany. Users can provide their zip code and vote for their favorite beer. They can then see on a map which beer is most popular in a zip code, county or state.",
                            role: "I developed Dein Pils together with a colleague in one weekend in 2020 as a joke.",
                            learnMore: "The Dein Pils website is currently offline.",
                            contact: "> Find the concept interesting? Contact me",
                        },
                    },
                    contact: {
                        title: "Always ready for the next adventure",
                        private: "Private",
                        business: "Business",
                    },
                }
            },
            de: {
                translation: {
                    // here we will place our translations...
                    title: {
                        photo: {
                            name: "Ich bin Henning.",
                            job: "Software-Engineer. Nebenberuflicher CEO. Hobbyradler.",
                            inTouch: "Du möchtest mir etwas sagen?",
                            clickHere: "Klicke hier",
                        },
                        about: {
                            title: "Über mich",
                            basics1: "Jahre alt aus einem Dorf in Niedersachsen",
                            basics2: "M.Sc. Wirtschaftsinformatik",
                            basics3: "Software-Engineer seit 2019, nebenberuflicher CEO seit 2021",
                            skills1: "Introvertiert, aber team- und kritikfähig",
                            skills2: "Suche neue Herausforderungen, lerne gerne Neues",
                            skills3: "Respektvoll, aber ehrlich mit der richtigen Portion Humor",
                        }
                    },
                    footer: {
                        imprint: "Impressum",
                        privacy: "Datenschutz",
                        handbuilt: "Handgefertigt mit ♥ und ✨",
                    },
                    navbar: {
                        contact: "Kontakt",
                    },
                    portfolio: {
                        title: "Was bisher geschah:",
                        comingSoon: "DEMNÄCHST",
                        role: "Meine Rolle",
                        learnMore: "Mehr erfahren",
                        readMore: "> Weiterlesen",
                        rocit: {
                            field: "ÖPNV",
                            description: "ROCIT ist ein Prognose- und Verspätungsanalysetool für öffentliche Verkehrsunternehmen. Es integriert sich in das Datenmodell des deutschen Standards für den ÖPNV (VDV-452/453/454), um den Betreiber mit den wichtigsten Informationen in Echtzeit und rückwirkend zu versorgen.",
                            role: "Ich leitete das Frontend-Team, das die Aufgabe hatte, die ROCIT-Single-Page-Application zu erstellen. Ich war auch an der Erstellung der ETL-Prozesse für das Data Warehouse beteiligt. Außerdem habe ich teilweise die Leitung des KI-Teams übernommen, das eine Echtzeit-Prognose-API mit Tensorflow erstellt hat.",
                            website: "> Offizielle Projektwebsite",
                            paper: "> Veröffentlichter Artikel",
                            enviroinfo: "> Enviroinfo 2020",
                        },
                        paloma: {
                            field: "Versicherungen",
                            description: "Paloma ist eine umfassende Plattform zur vollständigen Verwaltung von Lebens- und Sterbegeldversicherungen. Dies beinhaltet die Verwaltung von Mitgliedern und Verträgen, Buchhaltung und Zahlungsverwaltung, Briefverkehr, Statistiken, Datenmigrationen und Onlineprozesse.",
                            role: "Ich bin einer von zwei Gründern der Evonetic Software GmbH - das Unternehmen hinter der Software Paloma. Mein Geschäftspartner Jon-Patric Ewelt und ich haben Paloma von Grunde auf neu entwickelt und verbessern Paloma auch weiterhin gemäß den Bedürfnissen unserer Kunden.",
                            website: "> Offizielle Projektwebsite",
                        },
                        imis: {
                            field: "Gesundheit / COVID-19",
                            description: "IMIS bietet den Gesundheitsämtern ein digitales, standardisiertes Verfahren zur Erfassung und Meldung von COVID-19-Fällen. Es wurde ursprünglich im Rahmen des von der deutschen Regierung organisierten \"Wir-vs-Virus\"-Hackathons entwickelt. Nach seiner anfänglichen Entwicklung wurde es weiterentwickelt, bis die Ressourcen auf die bestehende Anwendung \"SORMAS\" übertragen wurden.",
                            role: "Ich war maßgeblich an der Entwicklung der Frontend-Anwendung von IMIS beteiligt. In geringerem Umfang habe ich am Backend gearbeitet und die IMIS REST API entwickelt. Außerdem war ich für die Erstellung der Projektwebsite verantwortlich.",
                            website: "> Offizielle Projektwebsite",
                            twitter: "> IMIS Twitter",
                            repo: "> Github Repository",
                        },
                        simplemattable: {
                            field: "UI Bibliothek",
                            description: "Simplemattable ist eine Open-Source-Angular-Bibliothek für die einfache und flexible Erstellung von Materialdesign-Tabellen. Sie ist seit 2018 im kontinuierlichen kommerziellen Einsatz.",
                            role: "Ich habe das Projekt 2018 ins Leben gerufen und pflege und entwickle die Bibliothek seither. Mit Ideen und Vorschlägen aus der Community verbessere ich simplemattable weiterhin Schritt für Schritt.",
                            website: "> Offizielle Projektwebsite",
                            npm: "> NPM Package",
                            repo: "> Github Repository",
                        },
                        skip: {
                            field: "Softwaretests",
                            description1: "Skip ist ein Testautomatisierungswerkzeug, das für System- und Akzeptanztests verwendet werden kann. Es bietet einen Rahmen für Entwickler, um Testvorlagen zu erstellen und zu veröffentlichen. Durch die Bereitstellung einer Konfiguration für diese Vorlagen können Testfälle auch von Personen ohne IT-Hintergrund erstellt werden.",
                            description2: "Skip lässt sich nahtlos in moderne CI/CD-Infrastrukturen wie Build-Systeme, Versionskontrollsysteme und Kubernetes-Cluster integrieren und bietet ein Plugin-System für die schnelle Erstellung von Testvorlagen.",
                            role: "Skip habe ich im Rahmen meiner Masterarbeit über die Automatisierung von System- und Akzeptanztests in IT-Landschaften entwickelt. Dazu habe ich mehrere Workshops mit verschiedenen Gruppen von Stakeholdern in einer deutschen Bank organisiert und moderiert. Aus den Workshops resultierten User Stories, die ich bei der Konzeption und Implementierung von Skip berücksichtigt habe.",
                            learnMore: "Skip ist derzeit closed source und meine Masterarbeit kann aufgrund eines Sperrvermerks nicht veröffentlicht werden. Wenn du an Skip oder meiner Masterarbeit interessiert bist, kannst du mich gerne kontaktieren. Ich bin sicher, dass wir eine Lösung finden können.",
                            contact: "> Kontaktiere mich",
                        },
                        vpex: {
                            field: "Produktivität",
                            description1: "VPEX ist ein einfach zu bedienender und leichtgewichtiger Editor für große Text-/XML-Dateien. Er ermöglicht das Anzeigen, Verarbeiten (Syntax- und Schema-Validierung) und Bearbeiten großer XML-Dateien.",
                            description2: "VPEX wurde entwickelt, um bei der Arbeit mit Dateien jeder Größe schnell und zuverlässig zu sein.",
                            role: "Ich habe das Projekt 2019 ins Leben gerufen, da ich mit größeren XML-Dateien zu tun hatte und die mir zur Verfügung stehenden Werkzeuge meinen Anforderungen nicht genügten. Mit den Ideen meiner Kollegen, die VPEX bis heute nutzen, habe ich VPEX so weit entwickelt, dass wir es in unserer täglichen Arbeit bequem nutzen können.",
                            repo: "> Github Repository",
                            contact: "> Interesse an VPEX? Kontaktiere mich",
                        },
                        simplex: {
                            field: "Finanzen",
                            description1: "Simplex war ein Onlinebanking-Tool zur Finanzanalyse. Mit Zugang zu einem Online-Banking-Account (unter Nutzung des HBCI/FinTS-Protokolls), konnte Simplex einen intuitiven Überblick über alle Finanzen verschaffen, indem es Transaktionen kategorisiert, Limits festlegt und unerwartetes Verhalten erkennt.",
                            description2: "Simplex wurde aufgrund der Änderungen, die durch die EU-Richtlinie Payment Service Directive II (PSD2) eingeführt wurden, eingestellt. Seit der PSD2 sollen Kontoinformationsdienste die von den Banken bereitgestellten APIs nutzen, anstatt die bestehende HBCI/FinTS-Onlinebanking-Infrastruktur zu verwenden. Aufgrund des hohen Aufwands, der mit der Neugestaltung verbunden wäre, haben wir uns stattdessen auf andere, vielversprechendere Projekte konzentriert.",
                            role: "Ich habe Simplex zusammen mit einem Kollegen im Jahr 2017 entwickelt, um einen Einstieg in Spring Boot, Scala und Angular zu finden. Während ich mich mehr auf die Frontend-Anwendung konzentrierte, war mein Kollege mehr an der Entwicklung der Backend-REST-API beteiligt.",
                            learnMore: "Sowohl Simplex als auch der Typescript HBCI/FinTS-Client \"SimpleHBCI\" sind Closed Source und nicht öffentlich zugänglich. Wenn jedoch Interesse besteht, zu sehen, was und wie wir entwickelt haben, oder du einen potenziellen Anwendungsfall für eine der Komponenten siehst, kannst du mich gerne kontaktieren.",
                            contact: "> Kontaktiere mich",
                        },
                        browserstartpage: {
                            field: "Produktivität",
                            description: "Browserstartpage ist eine konfigurierbare, einfach zu bedienende Startseite für deinen Browser. Sie ermöglicht es dir, deine Einstiegsseite ins Web individuell zu gestalten, indem du Widgets wie Links, Wetter oder komplette fremde Seiten in einen Teil deiner persönlichen Browser-Startseite einbettest.",
                            role: "Ich habe das Projekt 2018 ins Leben gerufen, als ich sah, dass einer meiner Dozenten eine persönliche, selbst erstellte HTML-Browser-Startseite hatte. Ich begann damit, eine einfache HTML-Seite selbst zu erstellen, aber dann bemerkte ich, dass ich etwas wollte, das einfacher zu konfigurieren und zu verwenden ist. Seitdem benutze ich Browserstartpage kontinuierlich und konnte den Code des Konfigurators in anderen Projekten wie ROCIT wiederverwenden, was meine Entwicklungsgeschwindigkeit deutlich erhöht hat.",
                            website: "> Offizielle Website (inkl. Registrierung)",
                        },
                        deinpils: {
                            field: "Unterhaltung",
                            description: "Dein Pils ist eine interaktive Bierkarte von Deutschland. Die Nutzer können ihre Postleitzahl angeben und für ihr Lieblingsbier abstimmen. Sie können dann auf einer Karte sehen, welches Bier in einer Postleitzahl, einem Landkreis oder einem Bundesland am beliebtesten ist.",
                            role: "Ich habe Dein Pils aus Spaß zusammen mit einem Kollegen an einem Wochenende im Jahr 2020 entwickelt.",
                            learnMore: "Die Website von Dein Pils ist derzeit offline.",
                            contact: "> Interessantes Konzept? Kontaktiere mich",
                        },
                    },
                    contact: {
                        title: "Immer bereit für das nächste Abenteuer",
                        private: "Privat",
                        business: "Geschäftlich",
                    },
                }
            }
        }
    });

export default i18n;
